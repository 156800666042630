import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import 'preline/preline';
import './modules/darkmode';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const toastOptions = {
	position: 'top-center',
	timeout: 2990,
	closeOnClick: true,
	pauseOnFocusLoss: false,
	pauseOnHover: false,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: false,
	closeButton: 'button',
	icon: true,
	rtl: false,
};

createApp(App).use(store).use(router).use(Toast, toastOptions).mount('#app');
