import axios from '@/modules/axios-helper';
import settings from '@/settings';
// import router from '@/router';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
	imagesError: null,
	images: null,
	attachedImages: null,
	fullscreenImage: null,
};

const getters = {
	getImages: state => state.images,
	getAttachedImages: state => state.attachedImages,
	getImagesError: state => state.imagesError,
	getFullscreenImage: state => state.fullscreenImage,
};

const mutations = {
	SET_IMAGES: (state, payload) => {
		state.images = payload;
	},
	SET_ATTACHED_IMAGES: (state, payload) => {
		state.attachedImages = payload;
	},
	SET_IMAGES_ERROR: (state, error) => {
		state.images = null;
		state.imagesError = error;
	},
	SET_FULLSCREEN_IMAGE: (state, payload) => {
		state.fullscreenImage = payload;
	},
};

const actions = {
	GET_IMAGES: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/image/all`);
			const images = response.data.data;
			context.commit('SET_IMAGES', images);
			return images;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_IMAGES_ERROR', err);
		}
	},
	GET_ATTACHED_IMAGES: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/image/attached`);
			const attachedImages = response.data.data;
			context.commit('SET_ATTACHED_IMAGES', attachedImages);
			return attachedImages;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_IMAGES_ERROR', err);
		}
	},
	DELETE_IMAGE: async (context, imageId) => {
		try {
			const response = await axios.delete(`${settings.backendUrl}/image/delete`, { params: { image_id: imageId } });
			const result = response.data.data;
			context.dispatch('GET_IMAGES');
			return result;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_IMAGES_ERROR', err);
		}
	},
	UPLOAD_IMAGE: async (context, image) => {
		try {
			const formData = new FormData();
			formData.append('image', image);
			const response = await axios.post(`${settings.backendUrl}/image/upload`, formData);
			const result = response.data.data;
			context.dispatch('GET_IMAGES');
			toast.success('Image has been uploaded');
			return result;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_IMAGES_ERROR', err);
			context.dispatch('GET_IMAGES');
		}
	},
	UPDATE_IMAGE: async (context, payload) => {
		try {
			const formData = new FormData();
			formData.append('updated_image', payload.updated_image);
			const response = await axios.put(`${settings.backendUrl}/image/edit`, formData, {
				params: { old_image_id: payload.old_image_id },
			});
			const result = response.data.data;
			context.dispatch('GET_IMAGES');
			toast.success('Image has been updated');
			return result;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_IMAGES_ERROR', err);
			context.dispatch('GET_IMAGES');
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
