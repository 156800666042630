<template>
	<footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
		<!-- Grid -->
		<div class="text-center">
			<div>
				<router-link
					:to="{ name: 'home' }"
					class="text-xl p-2 lg:px-4 md:mx-2 text-gray-500 rounded hover:text-gray-700 hover:dark:text-gray-100 transition-colors duration-300"
					>Home</router-link
				>
			</div>
			<!-- End Col -->

			<div class="mt-3">
				<p class="text-gray-500">Lampa Software © All Rights Reserved. {{ getCurrentYear }}.</p>
			</div>
		</div>
		<!-- End Grid -->
	</footer>
</template>

<script>
export default {
	name: 'FooterBar',
	computed: {
		getCurrentYear() {
			return new Date().getFullYear();
		},
	},
};
</script>
