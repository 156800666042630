<template>
	<div class="h-full">
		<div class="flex h-full items-center py-16">
			<main class="w-full max-w-md mx-auto p-6">
				<div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
					<div class="p-4 sm:p-7">
						<div class="text-center">
							<h1 class="block text-2xl font-bold text-gray-800 dark:text-white">Sign in</h1>
							<p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
								Don't have an account yet?
								<a
									class="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
									@click="$router.push({ name: 'register' })">
									Sign up here
								</a>
							</p>
						</div>
						<div class="mt-5">
							<!-- Form -->
							<form @submit.prevent>
								<div class="grid gap-y-4">
									<!-- userName -->
									<div>
										<div class="relative">
											<input
												v-model="username"
												type="text"
												id="username"
												name="username"
												placeholder="Enter your username"
												:maxlength="cfg.usernameMaxLength"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
												required
												aria-describedby="username-error" />
											<div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
												<svg
													class="h-5 w-5 text-red-500"
													width="16"
													height="16"
													fill="currentColor"
													viewBox="0 0 16 16"
													aria-hidden="true">
													<path
														d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
												</svg>
											</div>
										</div>
									</div>
									<!-- End userName -->

									<!-- password -->
									<div>
										<div class="flex justify-between items-center">
											<a
												class="text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
												href="#"
												>Forgot password?</a
											>
										</div>
										<div class="relative">
											<input
												v-model="password"
												type="password"
												id="password"
												name="password"
												:maxlength="cfg.passwordMaxLength"
												placeholder="Enter your password"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
												required
												aria-describedby="password-error" />
											<div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
												<svg
													class="h-5 w-5 text-red-500"
													width="16"
													height="16"
													fill="currentColor"
													viewBox="0 0 16 16"
													aria-hidden="true">
													<path
														d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
												</svg>
											</div>
										</div>
										<p class="hidden text-xs text-red-600 mt-2" id="password-error">
											{{ cfg.passwordMinLength }}+ characters required
										</p>
									</div>
									<!-- End password -->

									<button
										@click="login"
										type="submit"
										class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
										Sign in
									</button>
								</div>
							</form>
							<!-- End Form -->
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';
import config from '@/config';

export default {
	name: 'LoginView',
	data() {
		return {
			username: null,
			password: null,
		};
	},
	methods: {
		async login() {
			if (!this.username || !this.password) {
				this.toast.error('Please fill in all fields');
				return;
			}

			this.$store
				.dispatch('GET_ENTRY', {
					username: this.username,
					password: this.password,
				})
				.then(() => {
					this.toast.success('Login successful');
				});
		},
	},
	setup() {
		// TODO: add handler if user is already logged in he need to be redirected to home
		return { toast: useToast(), cfg: config };
	},
};
</script>
