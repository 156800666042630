<template>
	<!-- Main modal -->
	<div
		v-if="lunch"
		tabindex="-1"
		class="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-500 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90">
		<div class="relative p-4 w-full max-w-md max-h-full justify-center">
			<!-- Modal content -->
			<div class="relative bg-gray-100 rounded-lg shadow dark:bg-gray-800">
				<!-- Modal header -->
				<div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
					<h3 class="text-lg font-semibold text-gray-900 dark:text-white">Edit lunch</h3>
					<button
						@click="closeModal"
						class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						data-modal-toggle="crud-modal">
						<svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
						</svg>
					</button>
				</div>
				<!-- Modal body -->
				<form @submit.prevent class="p-4 md:p-5">
					<div class="grid gap-4 mb-4 grid-cols-2">
						<div class="col-span-2">
							<label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Restaurant</label>
							<input
								v-model="restaurant"
								:minlength="cfg.restaurantMinLength"
								:maxlength="cfg.restaurantMaxLength"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								type="text" />

							<label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Poll message</label
							>
							<input
								v-model="message"
								:minlength="cfg.poolMessageMinLength"
								:maxlength="cfg.poolMessageMaxLength"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								type="text" />
						</div>
						<div class="col-span-2 sm:col-span-1">
							<label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Is active?</label
							>
							<select
								id="category"
								v-model="isActive"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
								<option :value="true">Active</option>
								<option :value="false">Inactive</option>
							</select>
						</div>
					</div>
					<button
						@click="editLunch"
						type="submit"
						class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
						<svg
							class="w-6 h-6 text-gray-100 dark:text-gray-900"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m8 12 2 2 5-5m4.5 5.3 1-.9a2 2 0 0 0 0-2.8l-1-.9a2 2 0 0 1-.6-1.4V7a2 2 0 0 0-2-2h-1.2a2 2 0 0 1-1.4-.5l-.9-1a2 2 0 0 0-2.8 0l-.9 1a2 2 0 0 1-1.4.6H7a2 2 0 0 0-2 2v1.2c0 .5-.2 1-.5 1.4l-1 .9a2 2 0 0 0 0 2.8l1 .9c.3.4.6.9.6 1.4V17a2 2 0 0 0 2 2h1.2c.5 0 1 .2 1.4.5l.9 1a2 2 0 0 0 2.8 0l.9-1a2 2 0 0 1 1.4-.6H17a2 2 0 0 0 2-2v-1.2c0-.5.2-1 .5-1.4Z" />
						</svg>
						Confirm
					</button>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { useToast } from 'vue-toastification';
import config from '@/config';

export default {
	name: 'LunchUpdate',
	props: ['lunchId'],
	emits: ['close-lunch-edit'],
	data() {
		return {
			restaurant: null,
			message: null,
			isActive: null,
		};
	},
	computed: {
		lunch() {
			return this.$store.getters.getLunch;
		},
	},
	methods: {
		closeModal() {
			this.$emit('close-lunch-edit');
		},
		editLunch() {
			const data = {};
			const lunchId = this.lunch.id;
			if (this.isActive !== this.lunch.is_active) {
				data.is_active = this.isActive;
			}
			if (this.restaurant !== this.lunch.restaurant) {
				data.restaurant = this.restaurant;
			}
			if (this.message !== this.lunch.message) {
				data.message = this.message;
			}
			if (Object.keys(data).length === 0) {
				this.toast.warning('No changes were made.');
				this.closeModal();
				return;
			}
			this.$store.dispatch('UPDATE_LUNCH', { data, lunchId });
			this.closeModal();
		},
	},
	mounted() {
		this.restaurant = this.lunch.restaurant;
		this.message = this.lunch.message;
		this.isActive = this.lunch.is_active;
	},
	unmounted() {
		this.closeModal();
	},
	created() {
		this.$store.dispatch('GET_LUNCH', this.lunchId);
	},
	setup() {
		return { toast: useToast(), cfg: config };
	},
};
</script>
