<template>
	<h1 class="text-green-300 text-3xl">Fullscreen</h1>

	<!-- Main modal -->
	<div
		tabindex="-1"
		class="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-500 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90">
		<div class="relative p-4 w-full max-w-md max-h-full justify-center">
			<!-- Modal content -->
			<div class="relative bg-gray-100 w-full h-full rounded-lg shadow dark:bg-gray-800">
				<!-- Modal header -->
				<div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
					<h3 class="text-lg font-semibold text-gray-900 dark:text-white">Fullscreen</h3>
					<button
						@click="closeModal"
						class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						data-modal-toggle="crud-modal">
						<svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
						</svg>
					</button>
				</div>
				<!-- Modal body -->
				<div>
					<img :src="imagePath" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageDetail',
	emits: ['close-image-detail'],
	props: ['imagePath'],
	methods: {
		closeModal() {
			this.$emit('close-image-detail');
		},
	},
	beforeUnmount() {
		this.closeModal();
	},
	mounted() {},
};
</script>
