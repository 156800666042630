import axios from '@/modules/axios-helper';
import settings from '@/settings';
import router from '@/router';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
	lunchesError: null,
	lunches: null,
	activeLunches: null,
	lunchError: null,
	lunch: null,
};

const getters = {
	getLunches: state => state.lunches,
	getLunchesError: state => state.lunchesError,
	getActiveLunches: state => state.activeLunches,
	getLunch: state => state.lunch,
	getLunchError: state => state.lunchError,
};

const mutations = {
	SET_LUNCHES: (state, payload) => {
		state.lunches = payload;
	},
	SET_ACTIVE_LUNCHES: (state, payload) => {
		state.activeLunches = payload;
	},
	SET_LUNCH: (state, payload) => {
		state.lunch = payload;
	},
	SET_LUNCHES_ERROR: (state, error) => {
		state.lunchesError = error;
		state.lunches = null;
	},
	SET_LUNCH_ERROR: (state, error) => {
		state.lunchError = error;
		state.lunch = null;
	},
};

const actions = {
	GET_LUNCHES: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/lunch/all`);
			const lunches = response.data.data;
			context.commit('SET_LUNCHES', lunches);
			return lunches;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCHES_ERROR', err);
		}
	},
	GET_ACTIVE_LUNCHES: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/lunch/active`);
			const activeLunches = response.data.data;
			context.commit('SET_ACTIVE_LUNCHES', activeLunches);
			return activeLunches;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCHES_ERROR', err);
		}
	},
	GET_LUNCH: async (context, lunchId) => {
		try {
			const response = await axios.get(`${settings.backendUrl}/lunch/${lunchId}`);
			const lunch = response.data.data;
			context.commit('SET_LUNCH', lunch);
			return lunch;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
		}
	},
	DELETE_LUNCH: async (context, lunchId) => {
		try {
			const response = await axios.delete(`${settings.backendUrl}/lunch/delete`, { params: { lunch_id: lunchId } });
			const lunch = response.data.data;
			toast.success('Lunch has been deleted.');
			router.push({ name: 'lunches' });
			return lunch;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
			throw err;
		}
	},
	ATTACH_IMAGE: async (context, payload) => {
		try {
			const response = await axios.patch(`${settings.backendUrl}/lunch/attach-image`, null, {
				params: { lunch_id: payload.lunch_id, image_id: payload.image_id },
			});
			const result = response.data.data;
			toast.success('Image has been attached.');
			context.dispatch('GET_LUNCH', payload.lunch_id);
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
			router.push({ name: 'lunches' });
		}
	},
	DETACH_IMAGE: async (context, lunchId) => {
		try {
			const response = await axios.patch(`${settings.backendUrl}/lunch/detach-image`, null, {
				params: { lunch_id: lunchId },
			});
			const result = response.data.data;
			toast.success('Image has been detached.');
			context.dispatch('GET_LUNCH', lunchId);
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
			router.push({ name: 'lunches' });
		}
	},
	UPDATE_LUNCH: async (context, payload) => {
		try {
			const response = await axios.patch(`${settings.backendUrl}/lunch/edit`, payload.data, {
				params: { lunch_id: payload.lunchId },
			});
			const result = response.data.data;
			toast.success('Lunch has been updated.');
			context.dispatch('GET_LUNCH', payload.lunchId);
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
			router.push({ name: 'lunches' });
		}
	},
	CREATE_LUNCH: async (context, payload) => {
		try {
			const response = await axios.post(`${settings.backendUrl}/lunch/create`, payload);
			const result = response.data.data;
			toast.success('Lunch has been created.');
			context.dispatch('GET_LUNCHES');
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'lunches' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_LUNCH_ERROR', err);
			router.push({ name: 'lunches' });
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
