import axios from '@/modules/axios-helper';
import settings from '@/settings';
import router from '@/router';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
	messages: null,
	messagesError: null,
	message: null,
	messageError: null,
};

const getters = {
	getMessages: state => state.messages,
	getMessagesError: state => state.messagesError,
	getMessage: state => state.message,
};

const mutations = {
	SET_MESSAGES: (state, payload) => {
		state.messages = payload;
	},
	SET_MESSAGES_ERROR: (state, error) => {
		state.messages = null;
		state.messagesError = error;
	},
	SET_MESSAGE: (state, payload) => {
		state.message = payload;
	},
	SET_MESSAGE_ERROR: (state, error) => {
		state.message = null;
		state.messageError = error;
	},
};

const actions = {
	GET_MESSAGES: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/message/all`);
			const messages = response.data.data;
			context.commit('SET_MESSAGES', messages);
			return messages;
		} catch (err) {
			context.commit('SET_MESSAGES_ERROR', err);
			if (err.response.status !== 422) toast.error(err.response.data.detail);
		}
	},
	SEND_MESSAGE: async (context, payload) => {
		try {
			const response = await axios.post(`${settings.backendUrl}/message/send?chat_id=${payload.chatId}`, {
				text: payload.message,
			});
			const messages = response.data.data;
			return messages;
		} catch (err) {
			console.error(err);
			context.commit('SET_MESSAGE_ERROR', err);
		}
	},
	GET_MESSAGE: async (context, messageId) => {
		try {
			const response = await axios.get(`${settings.backendUrl}/message/${messageId}`);
			const message = response.data.data;
			context.commit('SET_MESSAGE', message);
			return message;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'messages' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_MESSAGE_ERROR', err);
		}
	},
	DELETE_MESSAGE: async (context, messageId) => {
		try {
			const response = await axios.delete(`${settings.backendUrl}/message/delete`, {
				params: { message_id: messageId },
			});
			const deletedMessage = response.data.data;
			toast.success('Message has been deleted');
			router.push({ name: 'messages' });
			return deletedMessage;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'messages' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_MESSAGE_ERROR', err);
		}
	},
	UPDATE_MESSAGE: async (context, payload) => {
		try {
			const response = await axios.patch(`${settings.backendUrl}/message/edit`, payload.data, {
				params: { message_id: payload.messageId },
			});
			const result = response.data.data;
			toast.success('Message has been updated.');
			context.dispatch('GET_MESSAGE', payload.messageId);
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'messages' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_MESSAGE_ERROR', err);
			router.push({ name: 'messages' });
		}
	},
	CREATE_MESSAGE: async (context, payload) => {
		try {
			const response = await axios.post(`${settings.backendUrl}/message/create`, payload);
			const result = response.data.data;
			toast.success('Message has been created.');
			context.dispatch('GET_MESSAGES');
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'messages' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_MESSAGE_ERROR', err);
			router.push({ name: 'messages' });
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
