import { createStore } from 'vuex';
import user from '@/store/modules/user';
import auth from '@/store/modules/auth';
import lunch from '@/store/modules/lunch';
import message from '@/store/modules/message';
import image from '@/store/modules/image';

export default createStore({
	state: {},
	mutations: {},
	actions: {},
	getters: {},
	modules: {
		auth,
		user,
		lunch,
		message,
		image,
	},
});
