<template>
	<div v-if="toggleEditUser">
		<UserUpdate :userId="selectedUserId" @close-user-edit="closeEditModal" />
	</div>
	<div v-if="users" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
		<!-- Grid -->
		<div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
			<!-- Card -->
			<div
				v-for="user in users"
				:key="user"
				class="group flex flex-col h-full bg-gray-100 border border-gray-200 shadow-lg rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-800/[.7]">
				<div class="h-52 flex flex-col justify-center items-center bg-gray-600 rounded-t-xl">
					<img :src="defaultImage" alt="..." class="h-full w-full object-cover" />
				</div>
				<div class="p-4 md:p-6">
					<span class="block mb-1 text-xs font-semibold uppercase text-purple-600 dark:text-purple-500">
						{{ user.id }} - {{ user.username }}
					</span>
					<h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300">
						Name:
						{{
							user.first_name && user.last_name
								? `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
								: 'No name provided'
						}}
					</h3>
					<p class="text-lg text-gray-800 dark:text-gray-300">Telegram ID: {{ user.user_id }}</p>
					<p class="text-gray-700 dark:text-gray-400">
						Roles: {{ user.is_admin ? 'Admin, ' : '' }}{{ user.is_partner ? 'Partner, ' : ''
						}}{{ user.is_member ? 'Member' : '' }}
					</p>
					<p class="text-sm text-gray-500 dark:text-gray-400">Created at: {{ formatDateTime(user.created_at) }}</p>
					<p class="text-sm text-gray-500 dark:text-gray-400">Updated at: {{ formatDateTime(user.updated_at) }}</p>
				</div>
				<div
					class="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
					<button
						v-if="user"
						@click="editUser(user.id)"
						class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-m font-bold rounded-es-xl bg-gray-200 text-gray-900 shadow-sm hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
						Edit
					</button>
					<button
						@click="deleteUser(user.id)"
						class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-m font-bold rounded-br-xl bg-gray-200 text-gray-900 shadow-sm hover:bg-red-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-800 dark:border-gray-700 dark:text-white dark:hover:bg-red-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
						Delete
					</button>
				</div>
			</div>
			<!-- End Card -->
		</div>
		<!-- End Grid -->
	</div>
	<!-- End Card Blog -->
</template>

<script>
import { formatDateTime } from '@/modules/utils';
import { useToast } from 'vue-toastification';
import userImagePlaceholder from '@/assets/user_placeholder.jpg';
import UserUpdate from '@/components/user/UserUpdate.vue';

export default {
	name: 'UserView',
	components: {
		UserUpdate,
	},
	data() {
		return {
			selectedUserId: null,
			toggleEditUser: false,
		};
	},
	computed: {
		users() {
			return this.$store.getters.getUsers;
		},
	},
	methods: {
		deleteUser(userId) {
			if (confirm('Are you sure you want to delete this user?')) {
				this.$store.dispatch('DELETE_USER', userId);
			}
		},
		capitalize(str) {
			if (!str) {
				return;
			}
			return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
		},
		editUser(userId) {
			this.selectedUserId = userId;
			this.toggleEditUser = true;
		},
		closeEditModal() {
			this.selectedUserId = null;
			this.toggleEditUser = false;
		},
	},
	created() {
		this.$store.dispatch('GET_USERS');
	},
	setup() {
		return { formatDateTime, toast: useToast(), defaultImage: userImagePlaceholder };
	},
};
</script>
