<template>
	<div
		tabindex="-1"
		class="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-500 dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-50">
		<h1 class="text-green-300 text-3xl">ID: {{ imageId }}</h1>
		<div v-if="imageId" class="fixed z-10 inset-0 overflow-y-auto">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div class="absolute inset-0 dark:bg-gray-900 bg-gray-500 bg-opacity-90 dark:bg-opacity-90"></div>
				</div>
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<div
					class="inline-block align-bottom bg-gray-100 dark:bg-gray-800 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline">
					<div class="header-modal py-3 px-4 bg-purple-700 text-white font-semibold shadow-lg text-lg">
						Upload image
					</div>
					<div class="body-modal p-4">
						<div class="content">
							<input @change="handleFileChange" class="text-gray-900 dark:text-gray-200" type="file" accept="image/*" />
						</div>
					</div>
					<div class="footer-modal px-4 pt-2.5 pb-4">
						<div class="flex flex-row-reverse">
							<button
								@click="closeModal"
								class="bg-red-500 hover:bg-red-600 dark:text-gray-900 text-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 px-8 rounded-lg py-2 mx-2">
								Cancel
							</button>
							<button
								@click="uploadImage"
								class="ml-2 bg-green-500 dark:text-gray-900 text-gray-100 focus:ring-4 focus:outline-none focus:ring-green-300 px-8 rounded-lg py-2 hover:bg-green-600 t">
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';
import settings from '@/settings';

export default {
	name: 'ImageCreate',
	props: ['imageId'],
	emits: ['close-image-update'],
	data() {
		return {
			settings: settings,
			selectedFile: null,
		};
	},
	methods: {
		uploadImage() {
			const data = {};
			const imageId = parseInt(this.imageId);
			if (!this.selectedFile) {
				this.toast.warning('No changes were made.');
				this.closeModal();
				return;
			}
			data.updated_image = this.selectedFile;
			data.old_image_id = imageId;

			this.$store.dispatch('UPDATE_IMAGE', data);
			this.closeModal();
		},
		closeModal() {
			this.$emit('close-image-update');
		},
		handleFileChange(event) {
			this.selectedFile = event.target.files[0];
		},
	},
	setup() {
		return { toast: useToast() };
	},
	unmounted() {
		this.closeModal();
	},
};
</script>
