<template>
	<div class="h-full">
		<div class="flex h-full items-center py-16">
			<main class="w-full max-w-md mx-auto p-6">
				<div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
					<div class="p-4 sm:p-7">
						<div class="text-center">
							<h1 class="block text-2xl font-bold text-gray-800 dark:text-white">Sign up</h1>
							<p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
								Already have an account?
								<a
									class="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
									@click="$router.push({ name: 'login' })">
									Sign in here
								</a>
							</p>
						</div>

						<div class="mt-5">
							<!-- Form -->
							<form @submit.prevent>
								<div class="grid gap-y-4">
									<!-- userName -->
									<div>
										<label for="username" class="block text-sm mb-2 dark:text-white">Username*</label>
										<div class="relative">
											<input
												v-model="username"
												type="text"
												id="username"
												name="username"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
												required
												:minlength="cfg.usernameMinLength"
												:maxlength="cfg.usernameMaxLength"
												placeholder="Enter your username"
												aria-describedby="username-error" />
											<div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
												<svg
													class="h-5 w-5 text-red-500"
													width="16"
													height="16"
													fill="currentColor"
													viewBox="0 0 16 16"
													aria-hidden="true">
													<path
														d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
												</svg>
											</div>
										</div>
										<p class="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid username</p>
									</div>
									<!-- End userName -->

									<!-- password -->
									<div>
										<label for="password" class="block text-sm mb-2 dark:text-white">Password*</label>
										<div class="relative">
											<input
												v-model="password"
												type="password"
												id="password"
												name="password"
												:minlength="cfg.passwordMinLength"
												:maxlength="cfg.passwordMaxLength"
												placeholder="Enter your password"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
												required
												aria-describedby="password-error" />
											<div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
												<svg
													class="h-5 w-5 text-red-500"
													width="16"
													height="16"
													fill="currentColor"
													viewBox="0 0 16 16"
													aria-hidden="true">
													<path
														d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
												</svg>
											</div>
										</div>
										<p class="hidden text-xs text-red-600 mt-2" id="password-error">
											{{ cfg.passwordMinLength }}+ characters required
										</p>
									</div>
									<!-- End password -->

									<!-- Form passwordConfirm -->
									<div>
										<label for="confirm-password" class="block text-sm mb-2 dark:text-white">Confirm Password*</label>
										<div class="relative">
											<input
												v-model="passwordConfirm"
												type="password"
												id="confirm-password"
												name="confirm-password"
												placeholder="Confirm your password"
												:minlength="cfg.passwordMinLength"
												:maxlength="cfg.passwordMaxLength"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
												required
												aria-describedby="confirm-password-error" />
											<div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
												<svg
													class="h-5 w-5 text-red-500"
													width="16"
													height="16"
													fill="currentColor"
													viewBox="0 0 16 16"
													aria-hidden="true">
													<path
														d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
												</svg>
											</div>
										</div>
										<p class="hidden text-xs text-red-600 mt-2" id="confirm-password-error">
											Password does not match the password
										</p>
									</div>
									<!-- End passwordConfirm -->

									<!-- userId -->
									<div>
										<label for="user_id" class="block text-sm mb-2 dark:text-white">User ID*</label>
										<div class="relative">
											<input
												v-model="userId"
												type="text"
												id="user_id"
												name="user_id"
												:maxlength="cfg.telegramIdMaxLength"
												placeholder="Enter your telegram-user ID"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
										</div>
									</div>
									<!-- End userId -->

									<!-- FirstName -->
									<div>
										<label for="first_name" class="block text-sm mb-2 dark:text-white">Name</label>
										<div class="relative">
											<input
												v-model="firstName"
												type="text"
												id="first_name"
												name="first_name"
												:minlength="cfg.nameMinLength"
												:maxlength="cfg.nameMaxLength"
												placeholder="Enter your name"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
										</div>
									</div>
									<!-- End FirstName -->

									<!-- lastName -->
									<div>
										<label for="last_name" class="block text-sm mb-2 dark:text-white">Surname</label>
										<div class="relative">
											<input
												v-model="lastName"
												type="text"
												id="last_name"
												name="last_name"
												:minlength="cfg.nameMinLength"
												:maxlength="cfg.nameMaxLength"
												placeholder="Enter your surname"
												class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
										</div>
									</div>
									<!-- End lastName -->

									<button
										@click="register()"
										type="submit"
										class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
										Sign up
									</button>
								</div>
							</form>
							<!-- End Form -->
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';
import config from '@/config';

export default {
	name: 'RegisterView',
	data() {
		return {
			username: null,
			password: null,
			passwordConfirm: null,
			userId: null,
			firstName: null,
			lastName: null,
		};
	},
	methods: {
		register() {
			// validating data
			if (!this.username || !this.password || !this.passwordConfirm || !this.userId) {
				this.toast.error('Please fill in all fields');
				this.password = null;
				this.passwordConfirm = null;
				return;
			}

			if (this.password !== this.passwordConfirm) {
				this.toast.error('Passwords do not match');
				this.password = null;
				this.passwordConfirm = null;
				return;
			}

			this.$store
				.dispatch('REGISTER_ENTRY', {
					username: this.username,
					password: this.password,
					user_id: this.userId,
					first_name: this.firstName,
					last_name: this.lastName,
				})
				.catch(() => {
					this.password = null;
					this.passwordConfirm = null;
				});
		},
	},
	setup() {
		return { toast: useToast(), cfg: config };
	},
};
</script>
