<template>
	<!-- Main modal -->
	<div
		v-if="selectedUser"
		tabindex="-1"
		class="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-500 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90">
		<div class="relative p-4 w-full max-w-md max-h-full justify-center">
			<!-- Modal content -->
			<div class="relative bg-gray-100 rounded-lg shadow dark:bg-gray-800">
				<!-- Modal header -->
				<div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
					<h3 class="text-lg font-semibold text-gray-900 dark:text-white">Edit user</h3>
					<button
						@click="closeModal"
						class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						data-modal-toggle="crud-modal">
						<svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
						</svg>
					</button>
				</div>
				<!-- Modal body -->
				<form @submit.prevent class="p-4 md:p-5">
					<div class="grid gap-4 mb-4 grid-cols-2">
						<div class="col-span-2">
							<label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
							<input
								v-model="firstName"
								:minlength="cfg.nameMinLength"
								:maxlength="cfg.nameMaxLength"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								type="text" />
							<label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Surname</label>
							<input
								v-model="lastName"
								:minlength="cfg.nameMinLength"
								:maxlength="cfg.nameMaxLength"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								type="text" />
							<label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telegram ID</label>
							<input
								v-model="telegramUserId"
								:maxlength="cfg.telegramIdMaxLength"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								type="text" />
						</div>
						<div class="col-span-2 sm:col-span-1">
							<label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Has admin rights?</label
							>
							<select
								id="category"
								v-model="isAdmin"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
								<option :value="true">Yes</option>
								<option :value="false">No</option>
							</select>
							<label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Has member rights?</label
							>
							<select
								id="category"
								v-model="isMember"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
								<option :value="true">Yes</option>
								<option :value="false">No</option>
							</select>
							<label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Has partner rights?</label
							>
							<select
								id="category"
								v-model="isPartner"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
								<option :value="true">Yes</option>
								<option :value="false">No</option>
							</select>
						</div>
					</div>
					<button
						@click="editUser"
						type="submit"
						class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
						<svg
							class="w-6 h-6 text-gray-100 dark:text-gray-900"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m8 12 2 2 5-5m4.5 5.3 1-.9a2 2 0 0 0 0-2.8l-1-.9a2 2 0 0 1-.6-1.4V7a2 2 0 0 0-2-2h-1.2a2 2 0 0 1-1.4-.5l-.9-1a2 2 0 0 0-2.8 0l-.9 1a2 2 0 0 1-1.4.6H7a2 2 0 0 0-2 2v1.2c0 .5-.2 1-.5 1.4l-1 .9a2 2 0 0 0 0 2.8l1 .9c.3.4.6.9.6 1.4V17a2 2 0 0 0 2 2h1.2c.5 0 1 .2 1.4.5l.9 1a2 2 0 0 0 2.8 0l.9-1a2 2 0 0 1 1.4-.6H17a2 2 0 0 0 2-2v-1.2c0-.5.2-1 .5-1.4Z" />
						</svg>
						Confirm
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';
import config from '@/config';

export default {
	name: 'userUpdate',
	emits: ['close-user-edit'],
	props: ['userId'],
	data() {
		return {
			firstName: null,
			lastName: null,
			telegramUserId: null,
			isAdmin: null,
			isMember: null,
			isPartner: null,
		};
	},
	methods: {
		closeModal() {
			this.$emit('close-user-edit');
		},
		editUser() {
			const data = {};
			const userId = this.selectedUser.id;
			if (this.firstName !== this.selectedUser.first_name) {
				data.first_name = this.firstName;
			}
			if (this.lastName !== this.selectedUser.last_name) {
				data.last_name = this.lastName;
			}
			if (this.telegramUserId !== this.selectedUser.user_id) {
				data.user_id = this.telegramUserId;
			}
			if (this.isAdmin !== this.selectedUser.is_admin) {
				data.is_admin = this.isAdmin;
			}
			if (this.isMember !== this.selectedUser.is_member) {
				data.is_member = this.isMember;
			}
			if (this.isPartner !== this.selectedUser.is_partner) {
				data.is_partner = this.isPartner;
			}
			if (Object.keys(data).length === 0) {
				this.toast.warning('No changes were made.');
				this.closeModal();
				return;
			}
			this.$store.dispatch('UPDATE_USER', { data, userId });
			this.closeModal();
		},
	},
	computed: {
		selectedUser() {
			return this.$store.getters.getUserSelected;
		},
	},
	created() {
		const userId = parseInt(this.userId);
		this.$store.dispatch('GET_SELECTED_USER', userId);
	},
	watch: {
		selectedUser: {
			handler: function () {
				this.firstName = this.selectedUser.first_name;
				this.lastName = this.selectedUser.last_name;
				this.telegramUserId = this.selectedUser.user_id;
				this.isAdmin = this.selectedUser.is_admin;
				this.isMember = this.selectedUser.is_member;
				this.isPartner = this.selectedUser.is_partner;
			},
			deep: true,
		},
	},
	unmounted() {
		this.closeModal();
	},
	setup() {
		return {
			toast: useToast(),
			cfg: config,
		};
	},
};
</script>
