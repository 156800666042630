<template>
	<div v-if="toggleEdit">
		<MessageUpdate @close-message-edit="closeEditModal" :messageId="messageId" />
	</div>
	<div v-if="message" class="py-8">
		<div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
			<div class="flex flex-col md:flex-row -mx-4">
				<div class="md:flex-1 px-4">
					<div class="h-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4">
						<img
							class="w-full h-full object-cover rounded-xl shadow-lg dark:shadow-slate-800/[.7]"
							:src="defaultImage"
							alt="Product Image" />
					</div>
					<div class="flex -mx-2 mb-4">
						<div class="w-1/2 px-2">
							<button
								@click="editMessage"
								class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-yellow-500 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb mx-1">
								<svg
									class="me-1 -ms-1 w-5 h-5"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24">
									<path
										stroke="currentColor"
										stroke-linecap="round"
										stroke-width="2"
										d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4" />
								</svg>
								Edit
							</button>
						</div>
						<div class="w-1/2 px-2">
							<button
								@click="deleteMessage(message.id)"
								class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb mx-1">
								<svg
									class="me-1 -ms-1 w-5 h-5"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24">
									<path
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
								</svg>
								Delete
							</button>
						</div>
					</div>
				</div>
				<div class="md:flex-1 px-4">
					<h2 class="text-2xl font-bold text-gray-800 dark:text-white mb-2">{{ message.title }}</h2>
					<p class="text-purple-500 text-sm mb-4">Author: {{ message.user.id }} | {{ message.user.username }}</p>
					<p class="text-gray-600 dark:text-gray-300 text-sm mb-4">Created: {{ formatDateTime(message.created_at) }}</p>
					<p class="text-gray-600 dark:text-gray-300 text-sm mb-4">Updated: {{ formatDateTime(message.updated_at) }}</p>
					<div class="flex mb-4 justify-center">
						<div class="mr-4">
							<span class="font-bold text-gray-700 dark:text-gray-300">Air alert message:</span>
							<span class="text-gray-600 dark:text-gray-300">
								<svg
									v-if="message.is_alert"
									class="ml-2 w-6 h-6 text-green-500 inline-block"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24">
									<path
										fill-rule="evenodd"
										d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
										clip-rule="evenodd" />
								</svg>
								<svg
									v-if="!message.is_alert"
									class="ml-2 w-6 h-6 text-red-500 inline-block"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24">
									<path
										fill-rule="evenodd"
										d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</div>
						<div>
							<span class="font-bold text-gray-700 dark:text-gray-300">Air alert cancel message:</span>
							<span class="text-gray-600 dark:text-gray-300">
								<svg
									v-if="message.is_alert_cancel"
									class="ml-2 w-6 h-6 text-green-500 inline-block"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24">
									<path
										fill-rule="evenodd"
										d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
										clip-rule="evenodd" />
								</svg>
								<svg
									v-if="!message.is_alert_cancel"
									class="ml-2 w-6 h-6 text-red-500 inline-block"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24">
									<path
										fill-rule="evenodd"
										d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</div>
					</div>
					<div class="inline whitespace-pre-line break-all">
						<span class="font-bold text-gray-700 dark:text-gray-300">Message text:</span>
						<p class="text-gray-600 dark:text-gray-300 text-sm mt-2">
							{{ message.text }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import settings from '@/settings';
import { formatDateTime } from '@/modules/utils';
import { useToast } from 'vue-toastification';
import MessageUpdate from '@/components/message/MessageUpdate.vue';
import messageImagePlaceholder from '@/assets/message_detail_placeholder.jpg';

export default {
	name: 'MessageDetail',
	components: { MessageUpdate },
	props: ['messageId'],
	data() {
		return {
			settings: settings,
			toggleEdit: false,
		};
	},
	computed: {
		message() {
			return this.$store.getters.getMessage;
		},
	},
	methods: {
		deleteMessage(messageId) {
			if (confirm('Are you sure you want to delete this message?')) {
				this.$store.dispatch('DELETE_MESSAGE', messageId);
			}
		},
		editMessage() {
			this.toggleEdit = true;
		},
		closeEditModal() {
			this.toggleEdit = false;
		},
	},
	created() {
		const messageId = parseInt(this.messageId);
		this.$store.dispatch('GET_MESSAGE', messageId);
	},
	setup() {
		return { formatDateTime, toast: useToast(), defaultImage: messageImagePlaceholder };
	},
};
</script>
