import axios from '@/modules/axios-helper';
import settings from '@/settings';
import { useToast } from 'vue-toastification';
import router from '@/router';

const toast = useToast();

const state = {
	user: null,
	users: null,
	userError: null,
	usersError: null,
	selectedUser: null,
	selectedUserError: null,
};

const getters = {
	getUser: state => state.user,
	getUsers: state => state.users,
	getUserError: state => state.userError,
	getUsersError: state => state.usersError,
	getUserSelected: state => state.selectedUser,
	getUserSelectedError: state => state.selectedUserError,
};

const mutations = {
	SET_USER: (state, payload) => {
		state.user = payload;
	},
	SET_USERS: (state, payload) => {
		state.users = payload;
	},
	SET_SELECTED_USER: (state, payload) => {
		state.selectedUser = payload;
	},
	SET_USER_ERROR: (state, error) => {
		state.user = null;
		state.userError = error;
	},
	SET_USERS_ERROR: (state, error) => {
		state.users = null;
		state.usersError = error;
	},
	SET_SELECTED_USER_ERROR: (state, error) => {
		state.selectedUser = null;
		state.selectedUserError = error;
	},
};

const actions = {
	GET_USER: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/user/me`);
			const user = response.data.data;
			context.commit('SET_USER', user);
			return user;
		} catch (err) {
			context.commit('SET_USER_ERROR', err);
		}
	},
	GET_USERS: async context => {
		try {
			const response = await axios.get(`${settings.backendUrl}/user/all`);
			const users = response.data.data;
			context.commit('SET_USERS', users);
			return users;
		} catch (err) {
			context.commit('SET_USERS_ERROR', err);
			if (err.response.status !== 422) toast.error(err.response.data.detail);
		}
	},
	DELETE_USER: async (context, userId) => {
		try {
			const response = await axios.delete(`${settings.backendUrl}/user/delete`, { params: { user_id: userId } });
			const deletedUser = response.data.data;
			toast.success('User has been deleted');
			context.dispatch('GET_USERS');
			return deletedUser;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_TOKEN_ERROR', err);
			throw err;
		}
	},
	GET_SELECTED_USER: async (context, userId) => {
		try {
			const response = await axios.get(`${settings.backendUrl}/user/${userId}`);
			const user = response.data.data;
			context.commit('SET_SELECTED_USER', user);
			return user;
		} catch (err) {
			context.commit('SET_SELECTED_USER_ERROR', err);
		}
	},
	UPDATE_USER: async (context, payload) => {
		try {
			const response = await axios.patch(`${settings.backendUrl}/user/update`, payload.data, {
				params: { user_id: payload.userId },
			});
			const result = response.data.data;
			toast.success('User has been updated.');
			context.dispatch('GET_USERS');
			return result;
		} catch (err) {
			if (err.response.status === 422) {
				router.push({ name: 'users' });
				return;
			}
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_USER_ERROR', err);
			router.push({ name: 'users' });
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
