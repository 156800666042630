<template>
	<div
		tabindex="-1"
		class="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-500 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90">
		<div class="fixed z-10 inset-0 overflow-y-auto">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div class="absolute inset-0 dark:bg-gray-900 bg-gray-500 opacity-95"></div>
				</div>
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<div
					class="inline-block align-bottom bg-gray-100 dark:bg-gray-800 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3/4 sm:w-3/4">
					<div class="header-modal py-3 px-4 bg-purple-700 text-white font-semibold shadow-lg text-lg">
						Choose an image
					</div>
					<div class="body-modal p-4">
						<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
							<div v-for="image in images" :key="image.id">
								<img
									@click="handleSelectedImage(image.id)"
									:class="{ ' border-8 border-purple-600': selectedImage === image.id }"
									class="w-full h-full object-cover rounded-lg cursor-pointer hover:outline-none focus:outline-none"
									:src="`${settings.backendUrl}/media/${image.filename}`"
									:alt="image.title" />
							</div>
						</div>
					</div>
					<div class="footer-modal px-4 pt-2.5 pb-4">
						<div class="flex flex-row-reverse">
							<button
								@click="closeModal"
								class="bg-red-500 hover:bg-red-600 dark:text-gray-900 text-gray-100 px-8 rounded-lg py-2 mx-2">
								Cancel
							</button>
							<button
								@click="attachImage"
								class="ml-2 bg-green-500 dark:text-gray-900 text-gray-100 px-8 rounded-lg py-2 hover:bg-green-600 t">
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import settings from '@/settings';
import { useToast } from 'vue-toastification';

export default {
	name: 'ImageChoice',
	props: ['lunchId'],
	data() {
		return {
			settings: settings,
			media: `${settings.backendUrl}/media/`,
			selectedImage: null,
		};
	},
	computed: {
		images() {
			return this.$store.getters.getImages;
		},
	},
	methods: {
		handleSelectedImage(imageId) {
			this.selectedImage = imageId;
		},
		closeModal() {
			this.$emit('close-image-choice');
		},
		attachImage() {
			if (!this.selectedImage) {
				if (confirm('Are you sure you want to detach an image?')) {
					this.$store.dispatch('DETACH_IMAGE', this.lunchId);
					this.closeModal();
					return;
				} else {
					return;
				}
			}
			if (confirm('Are you sure you want to attach this image?')) {
				this.$store.dispatch('ATTACH_IMAGE', {
					lunch_id: this.lunchId,
					image_id: this.selectedImage,
				});
				this.closeModal();
				return;
			}
		},
	},
	created() {
		this.$store.dispatch('GET_IMAGES');
	},
	setup() {
		const toast = useToast();
		return { toast };
	},
	unmounted() {
		this.closeModal();
	},
};
</script>
