<template>
	<div v-if="toggleUploadModal">
		<ImageCreate @close-image-upload="closeUploadModal" />
	</div>
	<div v-if="toggleFullscreen">
		<ImageDetail :imagePath="imagePath" @close-image-detail="closeFullscreenModal" />
	</div>
	<div v-if="toggleUpdateModal">
		<ImageUpdate :imageId="selectedImage" @close-image-update="closeUpdateModal" />
	</div>
	<button
		@click="toggleUploadModal = true"
		class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-8 mt-4">
		<svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
				clip-rule="evenodd"></path>
		</svg>
		Upload image
	</button>

	<div v-if="images">
		<section class="container mx-auto p-10 md:py-12 px-0 md:p-8 md:px-0">
			<section
				class="p-5 md:p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10 items-start">
				<section
					v-for="image in images"
					:key="image"
					class="p-5 py-10 bg-gray-100 dark:bg-gray-800 text-center transform duration-500 hover:-translate-y-2 cursor-pointer rounded-xl shadow-lg dark:shadow-slate-800/[.7]">
					<div @click="openFullscreen(`${media}${image.filename}`)">
						<img :src="`${media}${image.filename}`" alt="..." />
					</div>
					<h2 class="font-semibold mb-5 text-gray-900 dark:text-gray-300">{{ image.filename }}</h2>
					<button
						@click="editImage(image)"
						class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-yellow-500 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb mx-1">
						<svg
							class="me-1 -ms-1 w-5 h-5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-width="2"
								d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4" />
						</svg>
						Edit
					</button>
					<button
						@click="deleteImage(image.id)"
						class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb mx-1">
						<svg
							class="me-1 -ms-1 w-5 h-5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24">
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
						</svg>
						Delete
					</button>
				</section>
			</section>
		</section>
	</div>
</template>

<script>
import settings from '@/settings';
import { useToast } from 'vue-toastification';
import ImageCreate from '@/components/image/ImageCreate.vue';
import ImageDetail from '@/components/image/ImageDetail.vue';
import ImageUpdate from '@/components/image/ImageUpdate.vue';

export default {
	name: 'ImageView',
	components: { ImageCreate, ImageDetail, ImageUpdate },
	data() {
		return {
			settings: settings,
			media: `${settings.backendUrl}/media/`,
			toggleUploadModal: false,
			toggleFullscreen: false,
			toggleUpdateModal: false,
			selectedImage: null,
			imagePath: null,
		};
	},
	computed: {
		images() {
			return this.$store.getters.getImages;
		},
	},
	methods: {
		deleteImage(imageId) {
			if (confirm('Are you sure you want to delete this image?')) {
				this.$store.dispatch('DELETE_IMAGE', imageId);
				this.toast.success('Image has been deleted.');
			}
		},
		openFullscreen(imgPath) {
			this.imagePath = imgPath;
			this.toggleFullscreen = true;
		},
		closeUploadModal() {
			this.toggleUploadModal = false;
		},
		closeFullscreenModal() {
			this.toggleFullscreen = false;
			this.imagePath = null;
		},
		closeUpdateModal() {
			this.toggleUpdateModal = false;
		},
		editImage(image) {
			this.selectedImage = image.id;
			this.toggleUpdateModal = true;
		},
	},

	created() {
		this.$store.dispatch('GET_IMAGES');
	},
	setup() {
		return { toast: useToast() };
	},
};
</script>
