const config = {
	// user
	usernameMinLength: 3,
	usernameMaxLength: 20,
	passwordMinLength: 8,
	passwordMaxLength: 64,
	nameMinLength: 1,
	nameMaxLength: 35,
	telegramIdMaxLength: 128,
	// lunch
	restaurantMinLength: 1,
	restaurantMaxLength: 32,
	poolMessageMinLength: 1,
	poolMessageMaxLength: 100,
	// message
	messageTitleMinLength: 1,
	messageTitleMaxLength: 100,
	messageTextMinLength: 1,
	messageTextMaxLength: 4096,
};

export default config;
