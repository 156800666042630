import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/modules/auth';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/auth/LoginView.vue';
import RegisterView from '@/views/auth/RegisterView.vue';
import LogoutView from '@/views/auth/LogoutView.vue';
import UserView from '@/views/user/UserView.vue';
import LunchView from '@/views/lunch/LunchView.vue';
import LunchDetailView from '@/views/lunch/LunchDetail.vue';
import MessageView from '@/views/message/MessageView.vue';
import MessageDetailView from '@/views/message/MessageDetail.vue';
import ImageView from '@/views/image/ImageView.vue';
import ErrorView from '@/views/ErrorView.vue';

import { useToast } from 'vue-toastification';
const toast = useToast();
// import { IStaticMethods } from 'preline/preline';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
			meta: {
				title: 'Home',
				requiresAuth: true,
			},
		},
		{
			path: '/login',
			name: 'login',
			component: LoginView,
			meta: {
				title: 'Sign in',
			},
		},
		{
			path: '/register',
			name: 'register',
			component: RegisterView,
			meta: {
				title: 'Sign up',
			},
		},
		{
			path: '/logout',
			name: 'logout',
			component: LogoutView,
			meta: {
				title: 'Sign out',
			},
		},
		{
			path: '/users',
			name: 'users',
			component: UserView,
			meta: {
				title: 'Users',
				requiresAuth: true,
			},
		},
		{
			path: '/lunches',
			name: 'lunches',
			component: LunchView,
			meta: {
				title: 'Lunches',
				requiresAuth: true,
			},
		},
		{
			path: '/lunch/:lunchId',
			name: 'lunch-detail',
			component: LunchDetailView,
			props: true,
			meta: {
				title: 'Lunch Detail',
				requiresAuth: true,
			},
		},
		{
			path: '/messages',
			name: 'messages',
			component: MessageView,
			meta: {
				title: 'Messages',
				requiresAuth: true,
			},
		},
		{
			path: '/message/:messageId',
			name: 'message-detail',
			component: MessageDetailView,
			props: true,
			meta: {
				title: 'Message Detail',
				requiresAuth: true,
			},
		},
		{
			path: '/images',
			name: 'images',
			component: ImageView,
			meta: {
				title: 'Images',
				requiresAuth: true,
			},
		},
		{
			path: '/:catchAll(.*)*',
			name: 'error',
			component: ErrorView,
			props: true,
			meta: {
				title: 'Error',
			},
		},
	],
});

router.afterEach((to, from, failure) => {
	if (!failure) {
		setTimeout(() => {
			window.HSStaticMethods.autoInit();
		}, 100);
	}
});

const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

router.beforeEach((toRoute, fromRoute, next) => {
	// window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Admin Panel';
	if (toRoute.meta?.requiresAuth && !auth.isLoggedIn()) {
		next('/login');
		toast.error('You must be logged in to access this page.');
	}
	window.document.title = `Admin Panel: ${capitalizeFirstLetter(toRoute.name)}`;

	next();
});

export default router;
