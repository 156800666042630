<template>
	<div v-if="toggleUploadModal">
		<LunchCreate @close-lunch-create="closeUploadModal" />
	</div>
	<button
		@click="toggleUploadModal = true"
		type="submit"
		class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
		<svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
				clip-rule="evenodd"></path>
		</svg>
		Add lunch
	</button>
	<!-- <h2>{{ lunches }}</h2> -->
	<!-- Card Blog -->
	<div v-if="lunches" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
		<!-- Grid -->
		<div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
			<!-- Card -->
			<div
				v-for="lunch in lunches"
				:key="lunch"
				class="group flex flex-col h-full bg-gray-100 border border-gray-200 shadow-lg rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-800/[.7]">
				<div class="h-52 flex flex-col justify-center items-center bg-gray-600 rounded-t-xl">
					<img
						:src="lunch.image ? media + lunch.image?.filename : defaultImage"
						alt="..."
						class="h-full w-full object-cover" />
				</div>
				<div class="p-4 md:p-6">
					<span class="block mb-1 text-xs font-semibold uppercase text-purple-600 dark:purple-blue-500">
						{{ lunch.id }}
					</span>
					<h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
						{{ lunch.restaurant }}
					</h3>
					<p class="mt-3 text-gray-500 text-base flex items-center justify-center">
						{{ lunch.is_active ? 'Active' : 'Inactive' }}
						<svg
							v-if="lunch.is_active"
							class="w-6 h-6 text-green-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24">
							<path
								fill-rule="evenodd"
								d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
								clip-rule="evenodd" />
						</svg>
						<svg
							v-if="!lunch.is_active"
							class="w-6 h-6 text-red-500"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24">
							<path
								fill-rule="evenodd"
								d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z"
								clip-rule="evenodd" />
						</svg>
					</p>
				</div>
				<div
					class="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
					<router-link
						v-if="lunch"
						:to="{ name: 'lunch-detail', params: { lunchId: lunch.id } }"
						class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-m font-bold rounded-es-xl bg-gray-200 text-gray-900 shadow-sm hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
						>...</router-link
					>
				</div>
			</div>
			<!-- End Card -->
		</div>
		<!-- End Grid -->
	</div>
	<!-- End Card Blog -->
</template>

<script>
import settings from '@/settings';
import lunchPlaceholderImage from '@/assets/lunch_placeholder.jpg';
import LunchCreate from '@/components/lunch/LunchCreate.vue';

export default {
	name: 'LunchView',
	components: { LunchCreate },
	data() {
		return {
			settings: settings,
			media: `${settings.backendUrl}/media/`,
			defaultImage: lunchPlaceholderImage,
			toggleUploadModal: false,
		};
	},
	methods: {
		closeUploadModal() {
			this.toggleUploadModal = false;
		},
	},
	computed: {
		lunches() {
			return this.$store.getters.getLunches;
		},
		activeLunches() {
			return this.$store.getters.getActiveLunches;
		},
	},
	created() {
		this.$store.dispatch('GET_LUNCHES');
	},
};
</script>
