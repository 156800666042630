<template>
	<div v-if="toggleCreateMessage">
		<MessageCreate @close-message-create="closeCreateModal" />
	</div>
	<button
		@click="toggleCreateMessage = true"
		type="submit"
		class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
		<svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
				clip-rule="evenodd"></path>
		</svg>
		Create message
	</button>
	<div v-if="messages" class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
		<!-- Grid -->
		<div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
			<!-- Card -->
			<div
				v-for="message in messages"
				:key="message"
				class="group flex flex-col h-full bg-gray-100 border border-gray-200 shadow-lg rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-800/[.7]">
				<div class="h-52 flex flex-col justify-center items-center bg-gray-600 rounded-t-xl">
					<img :src="defaultImage" alt="..." class="h-full w-full object-cover" />
				</div>
				<div class="p-4 md:p-6">
					<span class="block mb-1 text-xs font-semibold uppercase text-purple-600 dark:text-purple-500">
						{{ message.id }}
					</span>
					<h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300">
						{{ message.title }}
					</h3>
					<span class="mt-3 text-gray-500 text-base flex items-center justify-center">
						<p class="text-gray-700 dark:text-gray-400">
							{{ message.text.length <= 32 ? message.text : message.text.substring(0, 32) + '...' }}
						</p>
					</span>
				</div>
				<div
					class="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
					<router-link
						v-if="message"
						:to="{ name: 'message-detail', params: { messageId: message.id } }"
						class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-m font-bold rounded-es-xl bg-gray-200 text-gray-900 shadow-sm hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
						>...</router-link
					>
				</div>
			</div>
			<!-- End Card -->
		</div>
		<!-- End Grid -->
	</div>
	<!-- End Card Blog -->
</template>

<script>
import messageImagePlaceholder from '@/assets/message_placeholder.jpg';
import MessageCreate from '@/components/message/MessageCreate.vue';

export default {
	name: 'MessageView',
	components: { MessageCreate },
	data() {
		return {
			toggleCreateMessage: false,
		};
	},
	computed: {
		messages() {
			return this.$store.getters.getMessages;
		},
	},
	methods: {
		closeCreateModal() {
			this.toggleCreateMessage = false;
		},
	},
	created() {
		this.$store.dispatch('GET_MESSAGES');
	},
	setup() {
		return { defaultImage: messageImagePlaceholder };
	},
};
</script>
