<template>
	<div class="relative">
		<p>You have been logged out</p>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
	name: 'LogoutView',

	mounted() {
		localStorage.removeItem('token');
		this.toast.success('You have been logged out');
		this.$router.push({ name: 'login' });
	},
	setup() {
		return { toast: useToast() };
	},
};
</script>
