<template>
	<div class="home">
		<div
			id="text"
			class="md:text-8xl uppercase bg-clip-text text-transparent bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 text-5xl font-black mt-4">
			Lampa Admin Panel
		</div>
		<h3
			v-if="user"
			class="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 dark:from-green-300 dark:via-blue-500 dark:to-purple-600">
			Hello,
			{{
				user?.first_name && user?.last_name
					? `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`
					: user.username
			}}
		</h3>
		<!-- message section -->
		<div class="flex justify-center">
			<div class="h-full w-11/12 flex items-center justify-center mt-16">
				<div class="w-full md:w-3/4 rounded-lg shadow-lg bg-gray-100 dark:bg-gray-900">
					<!-- text area -->
					<div class="relative p-4 pl-6 md:pl-20">
						<textarea
							v-model="messageText"
							class="bg-transparent pt-4 w-full text-black dark:text-white placeholder:text-gray-500 text-lg outline-none"
							placeholder="Type your message..."
							required
							rows="5"></textarea>
					</div>

					<!-- chat options -->
					<div class="pl-6 md:pl-20 pb-4 pr-4 flex flex-col md:flex-row md:justify-between">
						<div class="flex text-xl md:text-2xl items-center mb-3 md:mb-0">
							<select
								v-model="chatOption"
								required
								class="h-10 md:h-12 w-full md:w-40 p-2 m-3 border-2 text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-800 border-gray-300 dark:border-gray-600 rounded-md">
								<option :value="hrChat">HR Chat</option>
								<option :value="foodChat">Food Chat</option>
								<option :value="mainChat">Main Chat</option>
							</select>
						</div>
						<div>
							<!-- send button -->
							<button
								@click="sendMessage"
								class="bg-green-400 rounded-full text-gray-100 dark:text-gray-900 relative hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-300 inline-block py-2 px-4">
								Send
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end message section -->
		<div class="flex justify-center">
			<hr class="w-3/4 my-8 abs-gradient h-0.5" />
		</div>
		<!-- button section -->
		<div class="mt-4">
			<button
				@click="sendLunchMessage"
				type="submit"
				class="text-gray-100 dark:text-gray-900 inline-flex items-center bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
				<svg
					class="me-1 -ms-1 w-5 h-5"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 24 24">
					<path
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M12 5.5V3m0 2.4a5.3 5.3 0 0 1 5.1 5.3v1.8c0 2.4 1.9 3 1.9 4.2 0 .6 0 1.2-.5 1.2h-13c-.5 0-.5-.6-.5-1.2 0-1.2 1.9-1.8 1.9-4.2v-1.8A5.3 5.3 0 0 1 12 5.5ZM6 5 5 4M4 9H3m15-4 1-1m1 5h1M8.5 18a3.5 3.5 0 0 0 7 0h-7Z" />
				</svg>
				Lunches are ready
			</button>
		</div>
		<!-- end button section -->
	</div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
	name: 'HomeView',
	data() {
		return {
			mainChat: 'LAMPA_MAIN_CHAT',
			hrChat: 'LAMPA_HR_CHAT',
			foodChat: 'LAMPA_FOOD_CHAT',
			messageText: null,
			chatOption: null,
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		},
	},
	methods: {
		sendMessage() {
			if (!this.messageText || !this.chatOption) {
				this.toast.error('Please fill in all fields');
				return;
			}

			const approved = window.confirm('Are you sure you want to send this message?');
			if (approved) {
				this.$store
					.dispatch('SEND_MESSAGE', { chatId: this.chatOption, message: this.messageText })
					.then(() => {
						this.messageText = null;
						this.chatOption = null;
						this.toast.success('Message sent successfully');
					})
					.catch(() => {
						this.toast.error('Failed to send message');
					});
			}
		},
		capitalize(str) {
			if (!str) {
				return;
			}
			return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
		},
		sendLunchMessage() {
			if (confirm('Are you sure that you want to send the message?')) {
				const messageText = 'Всі ланчі на кухні. Смачного 🍔😉';
				this.$store
					.dispatch('SEND_MESSAGE', { chatId: this.foodChat, message: messageText })
					.then(() => {
						this.toast.success('Message sent successfully');
					})
					.catch(() => {
						this.toast.error('Failed to send message');
					});
				return;
			}
		},
	},
	setup() {
		return { toast: useToast() };
	},
	created() {
		this.$store.dispatch('GET_USER');
	},
};
</script>
