<template>
	<header class="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full text-sm">
		<nav
			class="mt-6 relative max-w-[85rem] w-full bg-white border border-gray-200 rounded-[36px] mx-2 py-3 px-4 md:flex md:items-center md:justify-between md:py-0 md:px-6 lg:px-8 xl:mx-auto dark:bg-gray-800 dark:border-gray-700"
			aria-label="Global">
			<div class="flex items-center justify-between">
				<a
					class="flex-none text-xl font-semibold dark:text-gray-200 text-gray-600 hover:text-blue-600 dark:hover:text-blue-600"
					href="/"
					aria-label="Brand"
					>Admin panel</a
				>
				<div class="md:hidden">
					<button
						type="button"
						class="hs-collapse-toggle w-8 h-8 flex justify-center items-center text-sm font-semibold rounded-full border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
						data-hs-collapse="#navbar-collapse-with-animation"
						aria-controls="navbar-collapse-with-animation"
						aria-label="Toggle navigation">
						<svg
							class="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round">
							<line x1="3" x2="21" y1="6" y2="6" />
							<line x1="3" x2="21" y1="12" y2="12" />
							<line x1="3" x2="21" y1="18" y2="18" />
						</svg>
						<svg
							class="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round">
							<path d="M18 6 6 18" />
							<path d="m6 6 12 12" />
						</svg>
					</button>
				</div>
			</div>
			<div
				id="navbar-collapse-with-animation"
				class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
				<div
					class="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-7 md:mt-0 md:ps-7">
					<!-- router buttons -->
					<router-link
						:to="{ name: 'users' }"
						class="font-bold md:py-6 text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-blue-600"
						:class="{ 'text-purple-600 dark:text-purple-500': $route.name === 'users' }"
						exact>
						Users
					</router-link>

					<router-link
						:to="{ name: 'lunches' }"
						class="font-bold md:py-6 text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-blue-600"
						:class="{ 'text-purple-600 dark:text-purple-500': $route.name === 'lunches' }"
						exact>
						Lunches
					</router-link>

					<router-link
						:to="{ name: 'messages' }"
						class="font-bold md:py-6 text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-blue-600"
						:class="{ 'text-purple-600 dark:text-purple-500': $route.name === 'messages' }"
						exact>
						Messages
					</router-link>

					<router-link
						:to="{ name: 'images' }"
						class="font-bold md:py-6 text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-blue-600"
						:class="{ 'text-purple-600 dark:text-purple-500': $route.name === 'images' }"
						exact>
						Images
					</router-link>
					<!-- end router buttons  -->

					<!-- dark mode button -->
					<button
						type="button"
						class="hs-dark-mode-active:hidden block hs-dark-mode group flex items-center text-gray-600 hover:text-blue-600 font-medium dark:text-gray-300 dark:hover:text-blue-600"
						data-hs-theme-click-value="dark">
						<svg
							class="flex-shrink-0 w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round">
							<path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
						</svg>
					</button>
					<button
						type="button"
						class="hs-dark-mode-active:block hidden hs-dark-mode group flex items-center text-gray-600 hover:text-blue-600 font-medium dark:text-gray-300 dark:hover:text-blue-600"
						data-hs-theme-click-value="light">
						<svg
							class="flex-shrink-0 w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round">
							<circle cx="12" cy="12" r="4" />
							<path d="M12 8a2 2 0 1 0 4 4" />
							<path d="M12 2v2" />
							<path d="M12 20v2" />
							<path d="m4.93 4.93 1.41 1.41" />
							<path d="m17.66 17.66 1.41 1.41" />
							<path d="M2 12h2" />
							<path d="M20 12h2" />
							<path d="m6.34 17.66-1.41 1.41" />
							<path d="m19.07 4.93-1.41 1.41" />
						</svg>
					</button>
					<!-- end dark mode button -->
					<!-- logout button -->
					<a
						class="flex items-center gap-x-2 font-bold text-gray-600 hover:text-blue-600 md:border-s md:border-gray-300 md:my-6 md:ps-6 dark:border-gray-700 dark:text-gray-400 dark:hover:text-blue-500"
						href="/logout">
						<svg
							class="flex-shrink-0 w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round">
							<path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
							<circle cx="12" cy="7" r="4" />
						</svg>
						Logout
					</a>
					<!-- end logout button -->
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
export default {
	name: 'NavBar',
	data() {
		return {
			showMobileMenu: false,
		};
	},
};
</script>

<!-- <script setup>
import { useDark, useToggle } from '@vueuse/core';

const isDark = useDark();

const toggleDark = useToggle(isDark);
</script> -->
