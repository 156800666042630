import axios from '@/modules/axios-helper';
import settings from '@/settings';
import router from '@/router';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
	token: null,
	tokenError: null,
};

const getters = {
	getToken: state => state.token,
	getTokenError: state => state.tokenError,
};

const mutations = {
	SET_TOKEN: (state, payload) => {
		state.token = payload;
		localStorage.setItem('token', payload);
	},
	DELETE_TOKEN: state => {
		state.token = null;
		localStorage.removeItem({ token: state.token });
	},
	SET_TOKEN_ERROR: (state, error) => {
		state.token = null;
		state.tokenError = error;
	},
};

const actions = {
	GET_ENTRY: async (context, payload) => {
		try {
			const response = await axios.post(`${settings.backendUrl}/auth/login`, payload);
			const token = response.data.data.token;
			context.commit('SET_TOKEN', token);
			router.push({ name: 'home' });
			return token;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_TOKEN_ERROR', err);
		}
	},
	REGISTER_ENTRY: async (context, payload) => {
		try {
			const response = await axios.post(`${settings.backendUrl}/auth/registration`, payload);
			const newUser = response.data.data;
			toast.success('Registration successful');
			router.push({ name: 'login' });
			return newUser;
		} catch (err) {
			if (err.response.status !== 422) toast.error(err.response.data.detail);
			context.commit('SET_TOKEN_ERROR', err);
			throw err;
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
