<template>
	<div class="max-w-[50rem] flex flex-col mx-auto w-full h-full">
		<header class="mb-auto flex justify-center w-full py-4">
			<nav class="px-4 sm:px-6 lg:px-8" aria-label="Global">
				<a class="flex-none text-xl font-semibold sm:text-3xl text-gray-800 dark:text-white" aria-label="Error"
					>An error has occurred</a
				>
			</nav>
		</header>

		<div class="text-center py-10 px-4 sm:px-6 lg:px-8 mb-52">
			<h1 class="block text-7xl font-bold text-gray-900 sm:text-9xl dark:text-white">
				{{ status }}
			</h1>
			<h1 class="block text-2xl font-bold text-white"></h1>
			<p class="mt-3 text-gray-900 dark:text-gray-400">Oops, something went wrong.</p>
			<p class="text-gray-900 dark:text-gray-400">{{ detail }}</p>
			<div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
				<a
					class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-purple-600 text-white hover:bg-purple-800 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
					href="/">
					<svg
						class="w-6 h-6 text-white dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 24 24">
						<path
							fill-rule="evenodd"
							d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
							clip-rule="evenodd" />
					</svg>
					Return to homepage
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		status: {
			type: Number,
			default: 404,
		},
		detail: {
			type: String,
			default: 'Sorry, we couldn`t find your page.',
		},
	},
};
</script>
