import { Buffer } from 'buffer';

const decodeToken = token => {
	try {
		return typeof token !== 'string' || token.indexOf('.') < 0
			? null
			: JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
	} catch (err) {
		return null;
	}
};

class AuthService {
	login(token) {
		localStorage.setItem(token);
	}

	logout() {
		localStorage.removeItem('token');
	}

	getToken() {
		return localStorage.getItem('token');
	}

	isLoggedIn() {
		const token = this.getToken();
		if (!token) return false;

		const decoded = decodeToken(token);
		if (!decoded) return false;
		const expirationMillis = decoded.exp * 1000;
		return expirationMillis > Date.now();
	}
}

const auth = new AuthService();

export default auth;
